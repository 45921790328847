import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Container,
} from "reactstrap";
import { Btn, H3 } from "../../../../AbstractElements";
import { restRequest } from "../../../../api-utils/apiQueries";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone-uploader";
import { toast } from "react-toastify";
import SelectField from "../../../../CommonElements/Select/SelectField";

const AddVendorService = ({
  showModal,
  toggle,
  selectedVendorService,
  setIsUpdated,
  id,
  location,
}) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [serviceName, setServiceName] = useState(
    selectedVendorService ? selectedVendorService.name : ""
  );
  const [amount, setAmount] = useState(
    selectedVendorService ? selectedVendorService.amount : 0
  );
  const [finalAmount, setFinalAmount] = useState(
    selectedVendorService ? selectedVendorService.final_amount : 0
  );
  const [description, setDescription] = useState(
    selectedVendorService ? selectedVendorService.description : ""
  );
  const [featureImg, setFeatureImg] = useState(null);
  const [image, setImage] = useState(null);
  const [otherImages, setOtherImages] = useState([]);
  const [selectedOtherImage, setSelectedOtherImage] = useState([]);
  const [isUploaded, setIsUploaded] = useState(true);

  console.log("Selected Vendor Service ->", selectedVendorService);
  console.log("form Location ->", location);

  const handleImageRemove = (name) => {
    console.log("Image name ->", name);

    setOtherImages((prev) => prev.filter((img) => img.originalname !== name));
  };

  useEffect(() => {
    if (selectedVendorService) {
      setSelectedCategories(
        selectedVendorService.sub_categories.map((item) => {
          return {
            value: item._id,
            label: item.name,
          };
        })
      );

      setServiceName(selectedVendorService.name);
      setAmount(selectedVendorService.amount);
      setFinalAmount(selectedVendorService.final_amount);
      setDescription(selectedVendorService.description);
      setOtherImages(selectedVendorService.other_image);
      setImage(selectedVendorService.feature_image);
    } else {
      setSelectedCategories([]);
      setServiceName("");
      setAmount(0);
      setDescription("");
    }
  }, [selectedVendorService]);

  // const handleCategoryId = async () => {
  //   let response = await restRequest.getCategoryServices(
  //     selectedCategories.value
  //   );
  //   let data = response.data.data;
  //   let setData = data.map((item) => {
  //     return {
  //       value: item._id,
  //       label: item.name,
  //     };
  //   });
  //   setServices(setData);
  //   console.log("Response ->", data);
  // };

  // useEffect(() => {
  //   if (selectedCategories) handleCategoryId();
  // }, [selectedCategories]);

  // single file
  const handleFeatureImgChange = async ({ meta, file }, status) => {
    setIsUploaded(false);
    if (status === "done") {
      let formData = new FormData();
      formData.append("from", "vendorService");
      formData.append("filed_name", "feature_image");
      formData.append("file", file);
      let response = await restRequest.uploadFileData(formData);
      console.log("File Response", response.data.data);
      if (response.data.code === 200) {
        setIsUploaded(true);
      }
      setFeatureImg(response.data.data);
    } else if (status === "removed") {
      setFeatureImg([]);
    }
    // if (status === "done") {
    //   setFeatureImg(file);
    // } else if (status === "removed") {
    //   setFeatureImg([]);
    // }
  };

  //Multi file
  // const handleOtherImagesChange = ({ meta, file }, status) => {
  //   if (status === "done") {
  //     setOtherImages((prev) => [...prev, file]);
  //   } else if (status === "removed") {
  //     setOtherImages((prev) => prev.filter((img) => img.name !== file.name));
  //   }
  // };

  const handleOtherImagesChange = async ({ meta, file }, status) => {
    setIsUploaded(false);
    if (status === "done") {
      // Create form data for the API request
      let formData = new FormData();
      formData.append("from", "vendorService");
      formData.append("filed_name", "other_image");
      formData.append("file", file);

      try {
        // Make the API request to upload the file
        let response = await restRequest.uploadFileData(formData);
        console.log("File Response", response.data.data);
        if (response.data.code === 200) {
          setIsUploaded(true);
        }
        // Update the state with the new file data
        setSelectedOtherImage((prev) => [...prev, response.data.data]);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else if (status === "removed") {
      // Remove the image from state
      setSelectedOtherImage((prev) =>
        prev.filter((img) => img.name !== file.name)
      );
    }
  };

  console.log("Selected ->", selectedCategories);

  const getCategories = async (category) => {
    console.log("Name ->", category);
    const response = await restRequest.getSubCategoriesData(category);
    const data = response.data.data;
    console.log("Category Data ->", data);
    const setData = data.map((role) => ({
      value: role._id,
      label: role.name,
    }));
    setCategories(setData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let allOtherImages = [...otherImages, ...selectedOtherImage];
    let newService = {
      // category_id: selectedCategories?.value,
      vendor_id: id,
      sub_categories: selectedCategories
        ? selectedCategories?.map((item) => item.value)
        : null,
      name: serviceName,
      amount: amount,
      final_amount: +finalAmount,
      description: description,
      feature_image:
        featureImg !== null ? featureImg : selectedVendorService.feature_image,
      other_image: allOtherImages,
      is_active: selectedVendorService ? selectedVendorService.is_active : true,
      business_address: {
        //   location: {
        lng: location.lng,
        lat: location.lat,
        // },
      },
    };

    if (selectedVendorService && selectedVendorService?._id) {
      newService.id = selectedVendorService._id;
    }

    let selected = selectedVendorService ? selectedVendorService : null;

    if (selected?._id) {
      delete newService.vendor_id;

      let response = await restRequest.updateVendorService(newService);
      if (response.data) {
        setIsUpdated(true);
        toast.success(response.data.message);
        toggle();
      }
      // console.log("Updated Services ->", newService);
    } else {
      // console.log("New Services ->", newService);
      let response = await restRequest.addNewVendorService(newService);
      if (response?.data) {
        setIsUpdated(true);
        toast.success(response.data.message);
        toggle();
      }
    }
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">
            {" "}
            {selectedVendorService
              ? "Update Vendor Service"
              : "Add Vendor Service"}{" "}
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-bookmark needs-validation"
            onSubmit={handleSubmit}
          >
            <Container fluid={true} className="add-product">
              <Row>
                <div
                  className="form-row "
                  style={{
                    maxHeight: "700px",
                    overflowY: "auto",
                  }}
                >
                  <Col lg="12">
                    <div className="product-info ">
                      <FormGroup className="col-md-12">
                        <label className="text-dark-md pb-1">Categories </label>
                        <SelectField
                          options={categories}
                          selectedOption={selectedCategories}
                          getData={getCategories}
                          setSelectedOption={setSelectedCategories}
                        />
                      </FormGroup>

                      <FormGroup className="col-md-12">
                        <Label>Service Name</Label>
                        <input
                          className="form-control"
                          value={serviceName}
                          type="text"
                          autoComplete="off"
                          onChange={(e) => setServiceName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-12">
                        <Label>Amount</Label>
                        <input
                          className="form-control"
                          value={amount}
                          type="number"
                          autoComplete="off"
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup className="col-md-12">
                        <Label>Final Amount</Label>
                        <input
                          className="form-control"
                          value={finalAmount}
                          type="number"
                          autoComplete="off"
                          onChange={(e) => setFinalAmount(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Description</Label>
                        <div className="border">
                          <CKEditor
                            editor={ClassicEditor}
                            data={description}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setDescription(data);
                            }}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="dz-message needsclick">
                          <Label>Image</Label>
                          {image && (
                            <div
                              style={{
                                position: "relative",
                                width: "100px",
                                height: "100px",
                              }}
                            >
                              <img
                                src={image?.path}
                                alt={image?.name}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <div
                                onClick={() => setImage(null)}
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  backgroundColor: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                  paddingLeft: "5px",
                                }}
                              >
                                &times;
                              </div>
                            </div>
                          )}
                          <Dropzone
                            onChangeStatus={handleFeatureImgChange}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent="Drop A File"
                            accept=".jpg,.jpeg,.png,.gif,.svg,.webp,.heif"
                            styles={{
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="dz-message needsclick">
                          <Label>Other Images</Label>
                          <div className="d-flex flex-wrap gap-3 mb-2">
                            {otherImages.map((file) => (
                              <div
                                key={file.name}
                                style={{
                                  position: "relative",
                                  width: "100px",
                                  height: "100px",
                                }}
                              >
                                <img
                                  src={file.path}
                                  alt={file.name}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                                <div
                                  onClick={() =>
                                    handleImageRemove(file.originalname)
                                  }
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    backgroundColor: "red",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "50%",
                                    width: "20px",
                                    height: "20px",
                                    cursor: "pointer",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  &times;
                                </div>
                              </div>
                            ))}
                          </div>
                          <Dropzone
                            onChangeStatus={handleOtherImagesChange}
                            // maxFiles={true}
                            multiple
                            inputContent="Drop Files"
                            accept=".jpg,.jpeg,.png,.gif,.svg,.webp,.heif"

                            // inputWithFilesContent={(files) =>
                            //   `${3 - files.length} more`
                            // }
                            // submitButtonDisabled={(files) => files.length < 3}
                          />
                        </div>
                      </FormGroup>
                    </div>
                    {/* </CardBody>
                    </Card> */}
                  </Col>
                </div>
              </Row>
            </Container>
            <div className="d-flex gap-4 justify-content-end">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>
              {/* {selectedVendorService ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )} */}
              {!isUploaded ? (
                <Btn attrBtn={{ color: "primary", type: "button" }}>
                  Wait...
                </Btn>
              ) : selectedVendorService ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddVendorService;
