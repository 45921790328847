import Dashboard from "../Components/PageLayout/Dashboard";
import CategoryWrapper from "../Components/Pages/catelog/category/CategoryWrapper";
import ServicesWrapper from "../Components/Pages/catelog/services/ServicesWrapper";
import CustomerDetails from "../Components/Pages/customer/CustomerDetails";
import CustomerWrapper from "../Components/Pages/customer/CustomerWrapper";
import LeadsDetails from "../Components/Pages/leads/LeadsDetails";
import LeadsWrapper from "../Components/Pages/leads/LeadsWrapper";
import ReviewsWrapper from "../Components/Pages/reviews/ReviewsWrapper";
import UnverifiedVendors from "../Components/Pages/unverified-vendor/UnverifiedVendors";
import ProfilesWrapper from "../Components/Pages/users/profiles/ProfilesWrapper";
import UsersWrapper from "../Components/Pages/users/UsersWrapper";
import VendorLeads from "../Components/Pages/vender/vendor-leads/VendorLeads";
import VendorDetails from "../Components/Pages/vender/VendorDetails";
import VendorsWrapper from "../Components/Pages/vender/VendorsWrapper";

export const routes = [
  {
    path: `/dashboard`,
    Component: <Dashboard />,
  },
  {
    path: `/catalog/categories`,
    Component: <CategoryWrapper />,
  },
  {
    path: `/catalog/sub-categories`,
    Component: <ServicesWrapper />,
  },
  {
    path: `/verified-vendors`,
    Component: <VendorsWrapper />,
  },
  {
    path: `/unverified-vendors`,
    Component: <UnverifiedVendors />,
  },
  {
    path: `/verified-vendors/:vendorId`,
    Component: <VendorDetails />,
  },
  // {
  //   path: `/verified-vendors/:vendorId/leads`,
  //   Component: <VendorLeads />,
  // },
  {
    path: `/leads`,
    Component: <LeadsWrapper />,
  },
  {
    path: `/leads/:leadId`,
    Component: <LeadsDetails />,
  },

  {
    path: `/customer-listing`,
    Component: <CustomerWrapper />,
  },
  {
    path: `/customer-listing/:customerId`,
    Component: <CustomerDetails />,
  },
  {
    path: `/users`,
    Component: <UsersWrapper />,
  },
  {
    path: `/users/profile`,
    Component: <ProfilesWrapper />,
  },
  {
    path: `/reviews`,
    Component: <ReviewsWrapper />,
  },
];
