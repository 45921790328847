import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { restRequest } from "../../../api-utils/apiQueries";
import Select from "react-select";
import { toast } from "react-toastify";

const AddNewUser = ({ showModal, toggle, selectedUser, setIsUpdated }) => {
  const [name, setName] = useState(selectedUser ? selectedUser.name : "");
  const [email, setEmail] = useState(selectedUser ? selectedUser.email : "");
  const [phone, setPhone] = useState(selectedUser ? selectedUser.mobile : "");
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(
    selectedUser
      ? { value: selectedUser?.profile?._id, label: selectedUser.profile.name }
      : null
  );

  const fetchProfilesData = async () => {
    let response = await restRequest.getUserProfiles();
    let data = response.data.data;
    console.log("Profile Data ->", data);

    let profileData = data.map((item) => {
      return {
        value: item._id,
        label: item.role,
      };
    });

    setProfiles(profileData);
  };
  console.log("Profile Data ->", profiles);
  // console.log("Selected User ->", selectedUser);

  useEffect(() => {
    fetchProfilesData();
  }, []);

  //   useEffect(() => {
  //     if (selectedUser) {
  //       const matchedProfile = profiles.find(
  //         (profile) => profile.label === selectedUser.profile.name
  //       );
  //       console.log("Selected Profile ->", matchedProfile);
  //       setSelectedProfile(matchedProfile);
  //     }
  //   }, [selectedUser]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      name: name,
      email: email,
      mobile: phone,
      role: selectedProfile.value,
    };
    console.log("User Data ->", data);

    if (selectedUser && selectedUser._id) {
      data._id = selectedUser._id;
    }
    let selected = selectedUser;
    if (selected?._id) {
      let response = await restRequest.updateUserData(data);
      console.log("Update User ->", response);
      toast.success(response?.data?.message);
      toggle();
      setIsUpdated(true);
    } else {
      let response = await restRequest.addUserData(data);
      console.log("Added User ->", response);
      toast.success(response.data.message);
      toggle();
      setIsUpdated(true);
    }
  };
  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">
            {selectedUser ? "Edit User" : "Add New User"}
            {""}
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            className="form-bookmark needs-validation"
            onSubmit={handleSubmit}
          >
            <FormGroup className="col-md-12">
              <Label>Name</Label>
              <input
                className="form-control"
                value={name}
                type="text"
                autoComplete="off"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Email</Label>
              <input
                className="form-control"
                value={email}
                type="email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Phone</Label>
              <input
                className="form-control"
                value={phone}
                type="number"
                autoComplete="off"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 10) {
                    setPhone(value);
                  }
                }}
                // required
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label>Profile </Label>
              <Select
                options={profiles}
                value={selectedProfile}
                onChange={(option) => setSelectedProfile(option)}
                required
              />
            </FormGroup>
            <div className="d-flex gap-4 justify-content-end">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggle,
                }}
              >
                Cancel
              </Btn>
              {selectedUser ? (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
              ) : (
                <Btn attrBtn={{ color: "primary", type: "submit" }}>Save</Btn>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddNewUser;
