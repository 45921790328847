import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import { Breadcrumbs, Btn } from "../../../AbstractElements";
import {
  CheckSquare,
  Edit3,
  Eye,
  Search,
  Trash2,
  XSquare,
} from "react-feather";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";
import { restRequest } from "../../../api-utils/apiQueries";
import ReviewDetails from "./ReviewDetails";
import { toast } from "react-toastify";

const ReviewsWrapper = () => {
  const [reviews, setReviews] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const navigate = useNavigate();

  const toggle = () => {
    setShowModal(!showModal);
    setSelectedProfile(null);
  };

  const handleDetailView = (data) => {
    setSelectedProfile(data);
    setShowModal(true);
    setReviewId(data._id);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };

  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const tableColumns = [
    {
      name: "Customer Name",
      selector: (row) => row?.user?.person_name,
      id: "role",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user?.person_name ? row?.user?.person_name : "-"}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Customer Number",
      selector: (row) => row?.user?.mobile_number,
      id: "count",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user?.mobile_number ? row?.user?.mobile_number : "-"}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Vendor Name",
      selector: (row) => row?.vendor,
      id: "count",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.vendor ? row?.vendor : "-"}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Service Name",
      selector: (row) => row?.service,
      id: "count",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.service ? row?.service : "-"}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Review Stars",
      selector: (row) => row?.review_stars,
      id: "count",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.review_stars}</span>
      ),
      // sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      id: "count",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.description ? row?.description : "-"}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => handleDetailView(d)}
          className="border border-none rounded text-primary me-2"
        >
          <Eye />
        </button>,
        <button
          onClick={() => handleStatusUpdated(d._id, 1)}
          className="border border-none rounded text-success me-2"
        >
          <CheckSquare />
        </button>,
        <button
          onClick={() => handleStatusUpdated(d._id, 2)}
          className="border border-none rounded text-danger me-2"
        >
          <XSquare />
        </button>,
      ],
    },
  ];

  console.log("Column name ->", sortColumn);

  const fetchReviewsData = async () => {
    let response = await restRequest.getPendingReviews(page);
    let data = response.data.data;
    console.log("Reviews Response ->", response.data.data);
    setReviews(data.reviews);
    setTotalPage(data.pager.totalPages);
    setTotalUsers(data.pager.totalRecords);
    setIsUpdated(false);
  };
  console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    if (isUpdated) fetchReviewsData();
  }, [isUpdated, page]);

  const handleStatusUpdated = async (id, status) => {
    let obj = {
      id: id,
      status: status,
    };
    let response = await restRequest.updateReviewStatus(obj);
    console.log("Status Update Response ->", response.data);

    if (response.data.code === 200) {
      setIsUpdated(true);
      toast.success(response.data.message);
    }
  };

  // const handleDeleteProfileRole = async (id) => {
  //   let data = {
  //     _id: id,
  //   };
  //   if (window.confirm("Are you sure , you want delete profile role?")) {
  //     let response = await restRequest.deleteProfileRole(data);
  //     console.log("Delete Profile Response ->", response);
  //     toast.success(response.data.message);
  //     setIsUpdated(true);
  //   }
  // };

  // const showLeadsDetails = (row) => {
  //   console.log("Row ->", row);
  //   navigate(`/customer-listing/${row._id}`);
  // };

  return (
    <Fragment>
      <Row>
        <div className="d-flex ">
          <Breadcrumbs mainTitle={`Reviews `} />
          <div className="d-flex gap-4">
            {/* <Col className="left-header horizontal-wrapper ps-0"> */}
            <div
              className={`input-group left-header horizontal-wrapper`}
              style={{ width: "300px" }}
            >
              <Input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={handleSearchValue}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                    setIsUpdated(true);
                  }
                }}
              />
              <span className="input-group-text mobile-search">
                <Search
                  onClick={() => {
                    setIsUpdated(true);
                  }}
                />
              </span>
            </div>

            {/* <Btn
              attrBtn={{
                color: "primary",
                className: "btn-block ",
                onClick: () => setShowModal(true),
              }}
            >
              <p className="mb-0 fs-7" style={{ width: "75px" }}>
                Add Profile
              </p>
            </Btn> */}
            {/* </Col> */}
          </div>
        </div>
        <Col sm="12">
          <Card>
            <CardBody className="data-tables">
              <DataTable
                data={reviews}
                columns={tableColumns}
                striped={true}
                center={true}
                pagination
                paginationServer
                paginationTotalRows={totalUsers}
                onChangePage={handlePageChange}
                paginationPerPage={20}
                paginationComponentOptions={paginationComponentOptions}
                onSort={handleSort}
                sortServer
              />
            </CardBody>
          </Card>
        </Col>
        {showModal && (
          <ReviewDetails
            showModal={showModal}
            toggle={toggle}
            id={reviewId}

            // selectedProfile={selectedProfile}
          />
        )}
      </Row>
    </Fragment>
  );
};

export default ReviewsWrapper;
