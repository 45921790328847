import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import DataTable from "react-data-table-component";
import { restRequest } from "../../../api-utils/apiQueries";
import { formatDate } from "../../commun-function/CommunFunction";
import { MdDelete } from "react-icons/md";
import { Eye } from "react-feather";
import ReviewDetails from "../reviews/ReviewDetails";
import UpdateStatusModal from "../reviews/UpdateStatusModal";

const VendorReview = ({ showReview, toggleReview, sourceId, source }) => {
  const [page, setPage] = useState(1);
  const [reviews, setReview] = useState([]);
  const [totalLeads, setTotalLeads] = useState();
  const [totalReviews, setTotalReviews] = useState();
  const [limit, setLimit] = useState();
  const [isUpdated, setIsUpdated] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [reviewId, setReviewId] = useState(null);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);
  const [statusCode, setStatusCode] = useState(null);

  const toggleUpdateStatus = () => setIsUpdateStatus(!isUpdateStatus);

  const toggle = () => {
    setShowModal(!showModal);
    // setReviewId(null);
  };

  console.log("Source id ->", sourceId);

  const handlePageChange = (page) => {
    setPage(page);
    setIsUpdated(true);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    noRowsPerPage: true,
    selectAllRowsItem: false,
  };

  const tableColumns = [
    {
      name: "Name",
      selector: (row) => row?.user?.person_name,
      id: "user.person_name",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user.person_name}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.user?.mobile_number,
      id: "user.person_name",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {row?.user?.mobile_number}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Review Stars",
      selector: (row) => row?.review_stars,
      id: "user.mobile_number",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row?.review_stars}</span>
      ),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      id: "user.mobile_number",
      cell: (row) => (
        <span
          style={{ cursor: "pointer" }}
          className={`border text-center px-2 rounded-3 text-light ${
            row?.status === 0
              ? "bg-warning"
              : row?.status === 1
              ? "bg-success"
              : row?.status === 2
              ? "bg-danger"
              : ""
          }`}
          onClick={() => handleChangeStatus(row)}
        >
          {row?.status === 0
            ? "Pending"
            : row?.status === 1
            ? "Approved"
            : row?.status === 2
            ? "Reject"
            : ""}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      id: "user.mobile_number",
      cell: (row) => (
        <span
          style={{
            display: "inline-block",
            maxWidth: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row?.description}
        </span>
      ),
      sortable: true,
    },

    {
      name: "Added Date ",
      selector: (row) => row?.serviceCount,
      id: "createdAt",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          {formatDate(row?.createdAt)}
        </span>
      ),
      sortable: true,
    },
    // {
    //   name: "Note",
    //   selector: (row) => row?.note,
    //   id: "createdAt",
    //   cell: (row, index) => (
    //     <span key={index} className="my-1">
    //       <Button
    //         className=" p-1 "
    //         color="primary"
    //         id={`Tooltip-${index}`}
    //         size="sm"
    //       >
    //         <MdOutlineSpeakerNotes size={20} />
    //       </Button>
    //       <Tooltip
    //         placement="top"
    //         isOpen={tooltipOpen[index] || false}
    //         target={`Tooltip-${index}`}
    //         toggle={() => toggleTooltip(index)}
    //         className=""
    //       >
    //         <p className="border border-2 px-2 py-1 rounded-pill mb-1">
    //           {row?.note ? row?.note : "No Not"}
    //         </p>
    //       </Tooltip>
    //     </span>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => handleReviewDetails(d)}
          className="border border-none rounded text-primary me-2"
        >
          <Eye size={22} />
        </button>,
        <button
          onClick={() => handleDeleteReview(d)}
          className="border border-none rounded text-danger me-2"
        >
          <MdDelete size={22} />
        </button>,
      ],
    },
  ];

  const fetchReviewsData = async () => {
    let response = await restRequest.getReviewsList(sourceId, page, source);
    let data = response.data.data;
    console.log("Reviews Response ->", data);
    setReview(data.reviews);
    setTotalReviews(data.pager.totalRecords);
    setLimit(data.pager.recordPerPage);
    setIsUpdated(false);
  };

  useEffect(() => {
    if (isUpdated) fetchReviewsData();
  }, [page, isUpdated]);

  const handleDeleteReview = async (data) => {
    console.log("Delete Data ->", data);

    if (window.confirm("Are you sure, you want delete review?")) {
      let response = await restRequest.deletedReview(data._id);
      console.log("Response Delete ->", response);
      setIsUpdated(true);
    }
  };

  const handleChangeStatus = (data) => {
    setIsUpdateStatus(true);
    setReviewId(data._id);
    setStatusCode(data.status);
  };

  const handleReviewDetails = (data) => {
    setReviewId(data._id);
    toggle();
  };

  return (
    <Fragment>
      <Modal isOpen={showReview} toggle={toggleReview} size="xl">
        <ModalHeader toggle={toggleReview} className="pt-4">
          <span className="fs-5 text-#534686">
            Vendor Review
            {/* {selectedVendor ? "Update Vendor" : "Add Vendor"}{" "} */}
          </span>
        </ModalHeader>
        <ModalBody className="position-relative">
          <Row>
            <Col sm="12">
              <Card>
                <Breadcrumbs mainTitle={`Vendors Review `} />
                <CardBody className="data-tables">
                  <DataTable
                    data={reviews}
                    columns={tableColumns}
                    striped={true}
                    center={true}
                    pagination
                    // selectableRows
                    paginationServer
                    paginationTotalRows={totalReviews}
                    onChangePage={handlePageChange}
                    paginationPerPage={limit}
                    paginationComponentOptions={paginationComponentOptions}
                    // onSort={handleSort}
                    sortServer
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {isUpdateStatus && (
        <UpdateStatusModal
          isStatus={isUpdateStatus}
          toggleStatus={toggleUpdateStatus}
          statusCode={statusCode}
          id={reviewId}
          setIsUpdated={setIsUpdated}
        />
      )}
      {showModal && (
        <ReviewDetails
          showModal={showModal}
          toggle={toggle}
          id={reviewId}
          // selectedProfile={selectedProfile}
          // setIsUpdated={setIsUpdated}
        />
      )}
    </Fragment>
  );
};

export default VendorReview;
