import React, { Fragment, useEffect, useState } from "react";
import { Form, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import { Btn } from "../../../AbstractElements";
import { restRequest } from "../../../api-utils/apiQueries";
import { toast } from "react-toastify";

const UpdateStatusModal = ({
  isStatus,
  toggleStatus,
  statusCode,
  id,
  setIsUpdated,
}) => {
  const [status, setStatus] = useState([
    { value: 0, label: "Pending" },
    { value: 1, label: "Approved" },
    { value: 2, label: "Reject" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    if (statusCode) {
      let selected = status.find((status) => status.value === statusCode);
      setSelectedStatus(selected);
    }
  }, [statusCode]);

  const handleUpdateStatus = async (e) => {
    e.preventDefault();
    if (!selectedStatus) return;

    let obj = {
      id: id,
      status: selectedStatus.value,
    };
    console.log("Update Status ->", obj);

    let response = await restRequest.updateReviewStatus(obj);
    console.log("Status Update Response ->", response.data);

    if (response.data.code === 200) {
      toast.success(response.data.message);
      toggleStatus();
      setIsUpdated(true);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isStatus} toggle={toggleStatus}>
        <ModalHeader toggle={toggleStatus} className="pt-4">
          <span className="fs-5 text-#534686">Update Status</span>
        </ModalHeader>
        <ModalBody className="position-relative">
          <Form onSubmit={handleUpdateStatus}>
            <div>
              <Label>Select Status Code</Label>
              <Select
                options={status}
                value={selectedStatus}
                onChange={(option) => setSelectedStatus(option)}
              />
            </div>
            <div className="d-flex gap-4 justify-content-end mt-5">
              <Btn
                attrBtn={{
                  color: "secondary ms-2",
                  onClick: toggleStatus,
                }}
              >
                Cancel
              </Btn>

              <Btn attrBtn={{ color: "primary", type: "submit" }}>Update</Btn>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default UpdateStatusModal;
