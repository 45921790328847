import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { H3, H5, H6, LI, P, UL } from "../../../AbstractElements";
import { Rating } from "react-simple-star-rating";
import { Phone, User } from "react-feather";
import { FaMobile, FaUser } from "react-icons/fa";
import { restRequest } from "../../../api-utils/apiQueries";
import { onlyDate } from "../../commun-function/CommunFunction";
import UpdateStatusModal from "./UpdateStatusModal";

const ReviewDetails = ({ showModal, toggle, id }) => {
  const [review, setReview] = useState(null);
  const [isUpdateStatus, setIsUpdateStatus] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);

  const toggleUpdateStatus = () => setIsUpdateStatus(!isUpdateStatus);

  const fetchReviewData = async () => {
    let response = await restRequest.getReviewDetailsData(id);
    console.log("ReviveDetails Response ->", response.data.data);
    const data = response.data.data;
    setReview(data);
    setIsUpdated(false);
  };

  useEffect(() => {
    if (isUpdated) fetchReviewData();
  }, [isUpdated]);

  const handleChangeStatus = () => {
    setIsUpdateStatus(true);
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="pt-4">
          <span className="fs-5 text-#534686">Review Details</span>
        </ModalHeader>
        <ModalBody className="faq-wrap">
          {review && (
            <Col className="featured-tutorial">
              <Row>
                <Col>
                  <Card className="features-faq product-box">
                    <CardBody>
                      <div className="d-flex align-items-center gap-5 mb-3">
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">
                            <FaUser size={22} />
                          </p>
                          <p className="mb-0">
                            {review.user.person_name
                              ? review.user.person_name
                              : "-"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">
                            <FaMobile size={20} />
                          </p>
                          <p className="mb-0">
                            {" "}
                            {review.user.mobile_number
                              ? review.user.mobile_number
                              : "-"}
                          </p>
                        </div>
                        <div>
                          {" "}
                          <span
                            style={{ cursor: "pointer" }}
                            className={`border text-center px-2 rounded-3 text-light ${
                              review.status === 0
                                ? "bg-warning"
                                : review.status === 1
                                ? "bg-success"
                                : review.status === 2
                                ? "bg-danger"
                                : ""
                            }`}
                            onClick={() => handleChangeStatus()}
                          >
                            {review.status === 0
                              ? "Pending"
                              : review.status === 1
                              ? "Approved"
                              : review.status === 2
                              ? "Reject"
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex gap-5 mb-3">
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Vendor :</p>
                          <p className="mb-0">
                            {" "}
                            {review?.vendor ? review.vendor : "-"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center gap-2 ">
                          <p className="mb-0">Service :</p>
                          <p className="mb-0">
                            {" "}
                            {review?.service ? review.service : "-"}
                          </p>
                        </div>
                      </div>
                      <div>
                        <H6>Description</H6>
                        <P>{review.description ? review.description : "-"}</P>
                      </div>
                      <div className="mt-3">
                        <H6>Files</H6>
                        <div className="d-flex flex-wrap gap-3 ">
                          {review?.files.map((image, index) => {
                            return (
                              <img
                                key={index}
                                src={image.path}
                                width={100}
                                height={100}
                                style={{ borderRadius: "10px" }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </CardBody>
                    <CardFooter className="d-flex align-items-center justify-content-between">
                      <span>{onlyDate(review.createdAt)}</span>
                      <Rating
                        className="pull-right"
                        initialValue={review.review_stars}
                        size="18px"
                        fillColor="#534686"
                        style={{ padding: "0" }}
                      />
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
            </Col>
          )}
        </ModalBody>
      </Modal>
      {isUpdateStatus && (
        <UpdateStatusModal
          isStatus={isUpdateStatus}
          toggleStatus={toggleUpdateStatus}
          statusCode={review.status}
          id={review._id}
          setIsUpdated={setIsUpdated}
        />
      )}
    </Fragment>
  );
};

export default ReviewDetails;
