import React, { Fragment, useEffect, useState } from "react";
import AddVendorService from "./AddVendorService";
import DataTable from "react-data-table-component";
import { Edit3, Eye, Search, Trash2 } from "react-feather";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Media,
  Row,
  Tooltip,
} from "reactstrap";
import { restRequest } from "../../../../api-utils/apiQueries";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import { fileURL } from "../../../../api-utils/constantVariables";
import { toast } from "react-toastify";
import { FaStar } from "react-icons/fa6";
import VendorReview from "../VendorReview";

const VendorServicesWrapper = ({ id, location }) => {
  const [vendorsServices, setVendorsServices] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [isUpdated, setIsUpdated] = useState(true);
  const [selectedVendorService, setSelectedVendorService] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [showReview, setShowReview] = useState(false);
  const [serviceId, setServiceId] = useState();
  const toggleReview = (data) => {
    setServiceId(data._id);
    setShowReview(!showReview);
  };

  const toggleTooltip = (index) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggle = () => {
    setShowModal(!showModal);
    setSelectedVendorService(null);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
  };

  const updatedVendor = async (row) => {
    // console.log("Row Data ->", row);
    setShowModal(true);
    setSelectedVendorService(null);
    let resp = await restRequest.getServiceById(row._id);
    console.log("Response ->", resp?.data?.data);
    let data = resp?.data?.data;

    setSelectedVendorService(data);
  };
  const handleSearchValue = (e) => {
    let data = e.target.value;
    setSearchValue(data);
  };

  const handleSort = (column, sortDirection) => {
    console.log("Column Data ->", column.id);
    setSortDirection(sortDirection === "desc" ? 0 : 1);
    setSortColumn(column.id);
    setIsUpdated(true);
  };

  const handleSwitch = async (index) => {
    const newServices = [...vendorsServices];
    const service = newServices[index];
    const updatedService = {
      ...service,
      is_active: !service.is_active,
    };
    newServices[index] = updatedService;

    let data = {
      service_id: updatedService._id,
      is_active: updatedService.is_active,
    };
    console.log("api function called", data);
    let response = await restRequest.updateServiceStatus(data);
    console.log("Change Status Response ->", response.data);
    if (response.data) {
      toast.success(response.data.message);
      setIsUpdated(true);
    }
  };

  const handleVerified = async (index) => {
    const newServices = [...vendorsServices];
    const service = newServices[index];
    const updatedService = {
      ...service,
      is_verified: !service.is_verified,
    };
    newServices[index] = updatedService;

    let data = {
      id: updatedService._id,
      is_verified: updatedService.is_verified,
    };
    console.log("api function called", data);
    let response = await restRequest.updateVendorServiceVerify(data);
    console.log("Change Status Response ->", response.data);
    if (response.data) {
      toast.success(response.data.message);
      setIsUpdated(true);
    }
  };

  const tableColumns = [
    {
      name: "Image",
      selector: (row) => row.feature_image,
      id: "category",
      cell: (row) => (
        <span className="text-secondary fw-medium">
          <img
            src={`${row.feature_image?.path}`}
            alt=""
            width={40}
            height={40}
          />
        </span>
      ),
      sortable: true,
    },
    {
      name: "Parent Category",
      selector: (row) => row.categories_id,
      id: "slug",
      cell: (row, index) => (
        <span key={index}>
          <Button
            className="me-1 p-1 px-2"
            color="primary"
            id={`Tooltip-${index}`}
            size="sm"
          >
            <Eye />
          </Button>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen[index] || false}
            target={`Tooltip-${index}`}
            toggle={() => toggleTooltip(index)}
            className=""
          >
            {row.categories.map((item, idx) => (
              <p
                className="border border-2 px-2 py-1 rounded-pill mb-1"
                key={idx}
              >
                {item}
              </p>
            ))}
          </Tooltip>
        </span>
      ),
      sortable: true,
    },
    {
      name: "Service Name",
      selector: (row) => row.name,
      id: "name",
      cell: (row) => (
        <span className="text-secondary fw-medium">{row.name}</span>
      ),
      sortable: () => handleSort("Name"),
    },

    {
      name: "Amount",
      selector: (row) => row.amount,
      id: "amount",
      cell: (row) => (
        <p>
          <span className="text-decoration-line-through me-2 text-secondary">
            {row.amount}
          </span>
          <span className="text-secondary fw-semibold">{row.final_amount}</span>
        </p>
      ),
      sortable: true,
    },
    {
      name: "Active",
      selector: (row) => row?.is_active,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row?.is_active}
                onChange={() => handleSwitch(index)}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Header Menu </Label> */}
        </Media>
      ),
    },
    {
      name: "Verified",
      selector: (row) => row?.is_verified,
      // id: "is_active",
      // sortable: true,
      cell: (row, index) => (
        <Media className="d-flex mt-2">
          <Media
            body
            className={`flex-grow-1 text-end icon-state switch-outline`}
          >
            <Label className="switch">
              <Input
                type="checkbox"
                checked={row?.is_verified}
                onChange={() => handleVerified(index)}
              />
              <span className="switch-state"></span>
            </Label>
          </Media>
          {/* <Label className="col-form-label m-r-10">Header Menu </Label> */}
        </Media>
      ),
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <button
          onClick={() => updatedVendor(d)}
          className="border border-none rounded text-success me-2"
        >
          <Edit3 />
        </button>,
        <button
          onClick={() => toggleReview(d)}
          className="border border-none rounded text-success me-2"
        >
          <FaStar className="text-warning" size={24} />
        </button>,

        // <button
        //   onClick={() => {}}
        //   className="border border-none rounded text-danger"
        // >
        //   <Trash2 />
        // </button>,
      ],
    },
  ];

  console.log("Column name ->", sortColumn);
  const fetchVendorsServices = async () => {
    let response = await restRequest.getVendorsServices(
      id,
      page,
      sortDirection,
      sortColumn,
      searchValue
    );
    let data = response.data.data;
    console.log("Response Vendor Service ->", data);
    setVendorsServices(data.vendorServices);
    setTotalPage(data.pager.totalPages);
    setIsUpdated(false);
  };
  console.log("IS UPDATED ->", isUpdated);

  useEffect(() => {
    if (isUpdated) fetchVendorsServices();
  }, [isUpdated]);
  return (
    <Fragment>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <div className="d-flex ">
                <Breadcrumbs mainTitle={"Vendors Services"} />
                <div className="d-flex gap-4">
                  {/* <Col className="left-header horizontal-wrapper ps-0"> */}
                  <div
                    className={`input-group left-header horizontal-wrapper`}
                    style={{ width: "300px" }}
                  >
                    <Input
                      type="text"
                      placeholder="Search.."
                      value={searchValue}
                      onChange={handleSearchValue}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setSearchValue(e.target.value);
                          setIsUpdated(true);
                        }
                      }}
                    />
                    <span className="input-group-text mobile-search">
                      <Search
                        onClick={() => {
                          setIsUpdated(true);
                        }}
                      />
                    </span>
                  </div>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "btn-block ",
                      onClick: () => setShowModal(true),
                    }}
                  >
                    <p className="mb-0 fs-7" style={{ width: "130px" }}>
                      Add Vendor Service
                    </p>
                  </Btn>
                  {/* </Col> */}
                </div>
              </div>
              {/* <Card> */}
              <CardBody className="data-tables">
                <DataTable
                  data={vendorsServices}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  pagination
                  // selectableRows
                  paginationServer
                  paginationTotalRows={totalPage * 10}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={paginationComponentOptions}
                  onSort={handleSort}
                  sortServer
                  // onColumnOrderChange=

                  // onSelectedRowsChange={handleRowSelected}
                  // clearSelectedRows={toggleDelet}
                />
              </CardBody>
              {/* </Card> */}
              {showModal && (
                <AddVendorService
                  showModal={showModal}
                  toggle={toggle}
                  selectedVendorService={selectedVendorService}
                  setIsUpdated={setIsUpdated}
                  id={id}
                  location={location}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showReview && (
        <VendorReview
          showReview={showReview}
          toggleReview={toggleReview}
          sourceId={serviceId}
          source="service"
        />
      )}
    </Fragment>
  );
};

export default VendorServicesWrapper;
